import {Component} from "preact";
import TestText from "./testText";
import {getMusicDescription} from "../api";
import About from "./about";

class Info extends Component {
  state = {
    pieceHTML: About
  }
  componentDidMount() {
    if (this.props.pieceID !== 0) {
      this.getData();
    }
  }

  componentDidUpdate(previousProps, previousState, snapshot) {
    if (this.props.pieceID !== previousProps.pieceID) {
      if (parseInt(this.props.pieceID)) {
        this.getData();
      } else {
        this.setState({pieceHTML: About});
      }
    }
  }

  getData() {
    getMusicDescription(this.props.pieceID).then(d => {
      this.setState({pieceHTML: d.descriptionMd});
    })
  }

  render(props, state, context) {
    let classes = "p-1.5 pt-4 w-full h-full bg-gray-650 rounded-md flex items-start justify-center overflow-auto "
    if (this.props.mobile) {
      classes += "info-mobile absolute top-0 "
      if (this.props.showInfo) {
        classes += "show "
      }
    } else {
      classes += "ml-2"
    }
    return <div class={classes}>
      <div class={"prose prose-white"} dangerouslySetInnerHTML={{__html: this.state.pieceHTML}}>
      </div>
    </div>;
  }
}

export default Info;