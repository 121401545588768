const About = `
    <h1>Music</h1>
    <p>This is all of the music I have officially published. I am interested in writing a lot of different kinds of music and 
    while I don't think I've reached the variety I'd like in my portfolio yet, I hope to soon.</p>
    <p>While I primarily specialize in composition, I also do arranging, transcription, orchestration, and production. 
    If you would like to collaborate with me on your next project, you can contact me below in the About Me section or 
    send me an email at <a href="mailto:peter@peterdevita.com">peter@peterdevita.com</a></p>
    <p>I also sell and rent sheet music for my pieces. I don't have an official store at the moment but you contact me for more info.</p>
    <p>Finally, thanks for reading down this far. If you like my music and would like to see some of my non-professional work, you can check out
     my <a href="/moremusic">music junk drawer.</a></p>
     <p>Thanks, I hope you enjoy my work!</p>
`;

export default About;