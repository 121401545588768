import {Component} from "preact";
import {getMusicCategories, getMusicPieces} from "../api";

const byYear = (a, b) => (parseInt(a.year) > parseInt(b.year) ? 1 : -1);
const byCategory = (a, b) => (a.category === b.category ? 0 : (a.category > b.category ? 1 : -1));
const sorts = [{sort: byYear, key: 'year'}, {sort: byCategory, key: 'category'}];

class Navbar extends Component {
  state = {
    sort: 0,
    categories: {},
    pieces: [],
    options: [
      {"name": "An Abandoned Porch", "year": "2018", "ensemble": "Piano", "duration": "8:00", ensembleType: "Solo (with accompaniment)"},
      {"name": "By Process", "year": "2019", "ensemble": "Contemporary Orchestra", "duration": "5:00", ensembleType: "Chamber Ensemble"},
      {"name": "Three Pieces for Soprano", "year": "2019", "ensemble": "Soprano, Piano", "duration": "7:30", ensembleType: "Solo (with accompaniment)"},
      {"name": "Silicon Chains", "year": "2019", "ensemble": "Voices, Electric Guitar and Bass, Synth", "duration": "10:00", ensembleType: "Chamber Ensemble"},
      {"name": "Manta Ray", "year": "2020", "ensemble": "Cello, Piano", "duration": "3:00", ensembleType: "Solo (with accompaniment)"},
      {"name": "Unsaid", "year": "2020", "ensemble": "Chamber Orchestra", "duration": "14:00", ensembleType: "Orchestra/Wind Ensemble"},
      {"name": "Somewhere", "year": "2020", "ensemble": "Clarinet, Horn, Piano, Double Bass, Drums", "duration": "5:00", ensembleType: "Chamber Ensemble"},
      {"name": "Tear It", "year": "2021", "ensemble": "String Quartet, Piano", "duration": "5:00", ensembleType: "Chamber Ensemble"},
      // {"name": "An Abandoned Porch", "year": "2018", "ensemble": "Piano", "duration": "8:00"},
      // {"name": "By Process", "year": "2019", "ensemble": "Contemporary Orchestra", "duration": "5:00"},
      // {"name": "Three Pieces for Soprano", "year": "2019", "ensemble": "Soprano and Piano", "duration": "7:30"},
      // {"name": "Silicon Chains", "year": "2019", "ensemble": "Voices, Electric Guitar and Bass, Synth", "duration": "10:00"},
      // {"name": "Manta Ray", "year": "2020", "ensemble": "Cello and Piano", "duration": "3:00"},
      // {"name": "Unsaid", "year": "2020", "ensemble": "Chamber Orchestra", "duration": "14:00"},
      // {"name": "Somewhere", "year": "2020", "ensemble": "Clarinet, Horn, Piano, Double Bass, Drums", "duration": "5:00"},
    ]
  }
  constructor(props) {
    super(props);
    this.optionClicked = this.optionClicked.bind(this);
    this.cycleSort = this.cycleSort.bind(this);
  }
  componentDidMount() {
    this.getData();
  }

  optionClicked(i) {
    this.props.showPiece(this.state.pieces[i].id);
  }

  getData() {
    getMusicCategories().then(d => {
      let cats = {};
      for (let i=0; i<d.length; i++) {
        cats[d[i].id] = d[i].name;
      }
      this.setState({categories: cats});
    });
    getMusicPieces().then(d => {
      this.setState({pieces: d});
    });
  }

  cycleSort() {
    let sort = this.state.sort + 1;
    if (sort > sorts.length - 1) {
      sort = 0;
    }
    this.setState({sort: sort});
  }

  render(props, state, context) {
    let options = this.state.pieces;
    let sort = sorts[this.state.sort];
    let renderList = []
    if (options.length) {
      options.sort(sort.sort);
      let previousHeader = options[0][sort.key];
      let previousHeaderText = previousHeader;
      if (sort.key === "category") {
        previousHeaderText = this.state.categories[previousHeader];
      }
      renderList.push(<NavHeader header={previousHeaderText}/>);
      for (let i = 0; i < options.length; i++) {
        if (options[i][sort.key] !== previousHeader) {
          previousHeader = options[i][sort.key];
          previousHeaderText = previousHeader;
          if (sort.key === "category") {
            previousHeaderText = this.state.categories[previousHeader];
          }
          renderList.push(<NavHeader key={i + "h"} header={previousHeaderText}/>);
        }
        renderList.push(<NavOption key={i} onClick={this.optionClicked} index={i} {...options[i]}/>);
      }
    }

    return <div class={"rounded-md relative overflow-auto h-full overflow-auto w-full border-2 border-gray-650 bg-gray-650 " + (this.props.mobile ? "absolute" : "max-w-xs xl:max-w-xs")}>
      <div class={"rounded-md overflow-auto h-full" + (this.props.mobile ? "" : "")}>
        <FilterHeader onClick={this.cycleSort}/>
        <NavOption title={"About/Info"} index={"about"} onClick={() => this.props.showPiece("about")}/>
        {renderList}
      </div>
    </div>;
  }
}

class FilterHeader extends Component {
  render(props, state, context) {
    return <div role={"button"} class={"absolute button circle accent float-bottom-right text-sm "} onClick={this.props.onClick}><FilterIcon class={"w-full h-full"}/></div>
  }
}

class NavHeader extends Component {
  render(props, state, context) {
    return <div class={"flex flex-col justify-center items-start mt-1 mb-1 p-1.5 h-14 text-gray-100 first:mt-0 last:mb-0"}>
      <h2>{this.props.header}</h2>
    </div>
  }
}


class NavOption extends Component {
  render(props, state, context) {
    return <div onClick={() => this.props.onClick(this.props.index)} role={"button"} class={"flex flex-col justify-center items-center mt-1 mb-1 p-1.5 bg-gray-600 h-20 text-gray-200 shadow-md first:mt-0 last:mb-0 hover:shadow-lg transition-shadow cursor-pointer"}>
      <div class={"flex justify-between items-center w-full mb-1"}>
        <span class={"whitespace-nowrap text-lg"}>{this.props.title}</span><span class={"text-sm"}>{this.props.year}</span>
      </div>
      <div class={"flex justify-between items-center w-full"}>
        <span class={"overflow-ellipsis overflow-hidden whitespace-nowrap w-4/5 text-sm"}>{this.props.instrumentation}</span><span class={"text-sm"}>{this.props.duration}</span>
      </div>
    </div>;
  }
}

class FilterIcon extends Component {
  render(props, state, context) {
    return <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
    </svg>;
  }
}


export default Navbar;