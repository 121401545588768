import {Component} from 'preact';
import styles from "../../scss/music.scss";
import Navbar from "./navbar";
import "preact/debug";
import "preact/devtools";
import Info from "./info";

const SHOW_PIECE = "SHOW_PIECE";
const page = document.getElementById("page");

class Music extends Component {
  state = {
    mobile: false,
    showInfo: false,
    pieceID: 0
  }

  ref = null;
  setRef = (dom) => this.ref = dom;

  constructor(props) {
    super(props);
    this.resize = this.resize.bind(this);
    this.showPiece = this.showPiece.bind(this);
    this.hideInfo = this.hideInfo.bind(this);
    window.addEventListener("popstate", this.backEvent.bind(this));
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
  }
  resize() {
    this.setState({mobile: this.ref.clientWidth <= 768})
  }
  backEvent(e) {
    console.log(e);
    if (e.state === SHOW_PIECE) {
      // If music is being displayed, go back, else do a page back
      if (Math.round(page.scrollTop / page.offsetHeight) === 1) {
        this.hideInfo();
      } else {
        history.back();
      }
      history.back();
    }
  }

  showPiece(ID) {
    if (this.state.mobile) {
      this.setState({showInfo: true});
      history.pushState(SHOW_PIECE, SHOW_PIECE);
      history.pushState(SHOW_PIECE, SHOW_PIECE);
    }
    this.setState({pieceID: ID})
  }

  hideInfo(index) {
    this.setState({showInfo: false});
  }

  render(props, state, context) {
    // max-w-5xl
    return <div class={"mcontainer overflow-auto w-full h-full mx-auto mt-2 mb-2 bg-gray-700 rounded-md drop-shadow flex flex-col p-1.5 " + (this.state.mobile ? " pt-1.5" : "p-1.5")} ref={this.setRef}>
      <Header mobile={this.state.mobile} showInfo={this.state.showInfo} hideInfo={this.hideInfo}/>
      <div class={"relative overflow-hidden w-full h-full " + (this.state.mobile ? "" : "flex")}>
        <Navbar mobile={this.state.mobile} showPiece={this.showPiece}/>
        <Info mobile={this.state.mobile} showInfo={this.state.showInfo} pieceID={this.state.pieceID}/>
      </div>
    </div>;
  }
}

class Header extends Component {
  render(props, state, context) {
    let backButton;
    if (this.props.mobile) {
      backButton = <div role={"button"} onClick={this.props.hideInfo} class={"button p-1 accent h-8 w-12 animate " + (this.props.mobile && this.props.showInfo ? "show" : "")}>
        <BackArrow class={"h-full"}/>
      </div>;
    }
    return <div class={"flex justify-around items-center text-gray-100 p-1"}>
      <div class={"flex-1"}>{backButton}</div>
      <span class={"flex-1 flex justify-center"}>Music</span>
      <div class={"flex-1"}/>
    </div>;
  }
}

class BackArrow extends Component {
  render(props, state, context) {
    return <svg {...this.props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
      <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
    </svg>;
  }
}



export default Music;