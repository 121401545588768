import {Component, Fragment} from "preact";

class TestText extends Component {
  render(props, state, context) {
    return <>
      <h1>Aasdfasdf</h1>
      <p>asdpfuihpawuiefp aspdkf d faksjdpfkl jslfs;ald fjfuih4eiu weljk jflasf.  sdjf sdf.asdf sf.asdfj asoijf jf jasd;fljasd; fsdljf kdj jsodfjpweioj </p>
      <h2>apjsodfjiasdf</h2>
      <ol>
        <li>asdf</li>
        <li>zxcv</li>
        <li>qwer</li>
      </ol>
      <p>asdhfoiuahsdofhasdf asdf sdf sdfksjdpfoij epof;sklj f;lj dlfj ovjioj ovij dovm aklsdj fasdjlfk sdifsduiofiweu erh in jkfh akjvhcuivh oeuiwhl kfu</p>
      <h2>ioe3j dnfioa 8f 4nzjc</h2>
      <p>jfjp djfosjf oiejiowf ojdojf pasdjfpl sdjsd ; klj;asdljf ;lasj ;flj</p>
      <p>jfjp djfosjf oiejiowf ojdojf pasdjfpl sdjsd ; klj;asdljf ;lasj ;flj</p>
      <p>jfjp tmoig ju irthi ghiudhfgjkv hhj knljksdfh uieb ihqeh iozxd jklfj riqwiic xcjo ;lasj ;flj</p>
    </>;
  }
}

export default TestText;